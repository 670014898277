import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
  where,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { db, fetchDocDictionary } from "../../services/firebase";
import InventoryDataService from "../../services/inventories.service";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Typography from "@mui/material/Typography";

import { Formik, Form, Field } from "formik";
import { CustomizedSelect } from "../../common/select";

import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { GridActionsCellItem } from "@mui/x-data-grid";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export const ReportInventories = () => {
  const [dateBegin, setDateBegin] = useState(dayjs());
  const [dateEnd, setDateEnd] = useState(dayjs());
  const formikRef = useRef();
  const navigate = useNavigate();

  const [tableLoading, setTableLoading] = useState(false);
  const [tankInventories, setTankInventories] = useState([]);
  const [truckInventories, setTruckInventories] = useState([]);

  const [products, setProducts] = useState();
  const [trucks, setTrucks] = useState();

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };


  const handleDeleteClick = (event) => async () => {
    //setRows(rows.filter((row) => row.id !== id));
    setTableLoading(true);
    console.log(event);
    //const done = await new Promise((res) => setTimeout(res, 2500));
    InventoryDataService.delete(event.id);
    setTableLoading(false);

    if (event.tankNumber) {
      console.log("tank deleted");
      setTankInventories(
        tankInventories.filter((tank) => tank.id !== event.id)
      );
    } else {
      console.log("truck deleted");
      setTruckInventories(
        truckInventories.filter((truck) => truck.id !== event.id)
      );
    }

    //setProducts(products.filter((product) => product.id !== id));
  };

  const [locations, locationsLoading, locationsError] = useCollectionOnce(
    query(
      collection(db, "locations-p"),
      where("active", "==", true),
      orderBy("name")
    )
  );

  const [users, usersLoading, usersError] = useCollectionOnce(
    query(
      collection(db, "users"),
      orderBy("name")
    )
  );

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateBegin", dateBegin);
    }
  }, [dateBegin]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("dateEnd", dateEnd);
    }
  }, [dateEnd]);

  const tankColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      headerAlign: "left",
      editable: false,
    },
    {
      field: "tankNumber",
      headerName: "Tank #",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    { field: "productName", headerName: "Product", flex: 1, editable: false },
    {
      field: "gallons",
      headerName: "Gallons",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "percentOfCapacity",
      headerName: "%",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Math.round(
          Number(params.value)
        ).toLocaleString();
        return `${valueFormatted} %`;
      },
    },
    {
      field: "reportingDate",
      headerName: "Date",
      flex: 1,
      type: "dateTime",
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
    { field: "userName", headerName: "User", flex: 1, editable: false },
    { field: "driverNumber", headerName: "Driver #", flex: 1, editable: false },
    {
      field: "createdDate",
      headerName: "Created",
      flex: 1,
      type: "dateTime",
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.createdDate.toDate()).format("M/D/YY h:mm a"),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (action) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(action.row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const truckColumns = [
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      headerAlign: "left",
      editable: false,
    },
    {
      field: "truckNumber",
      headerName: "Truck #",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "compartment",
      headerName: "Compartment",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    { field: "productName", headerName: "Product", flex: 1, editable: false },
    {
      field: "gallons",
      headerName: "Gallons",
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: false,
    },
    {
      field: "reportingDate",
      headerName: "Date",
      flex: 1,
      type: "dateTime",
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.reportingDate.toDate()).format("M/D/YY"),
    },
    { field: "userName", headerName: "User", flex: 1, editable: false },
    { field: "driverNumber", headerName: "Driver #", flex: 1, editable: false },
    {
      field: "createdDate",
      headerName: "Created",
      flex: 1,
      type: "dateTime",
      editable: false,
      valueGetter: ({ value }) => value && value.toDate(),
      renderCell: (params) =>
        moment(params.row.createdDate.toDate()).format("M/D/YY h:mm a"),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (action) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(action.row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={1}
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h5">Inventory</Typography>

        <Box display="flex">
          <Button
            variant="text"
            onClick={() => navigate("/maintenance")}
            startIcon={<BackspaceTwoToneIcon />}
          >
            Back
          </Button>
        </Box>
      </Box>
      <Formik
        innerRef={formikRef}
        initialValues={{ locationId: "", dateBegin: dayjs(), dateEnd: dayjs() }}
        onSubmit={async (values, helpers) => {
          //console.log("sumbitted", values);

          setTableLoading(true);

          let productsDictionary = products;

          if (!productsDictionary) {
            const dict = await fetchDocDictionary(
              query(collection(db, "products-p"))
            );
            setProducts(dict);
            productsDictionary = dict;
            //console.log("products-p", productsDictionary);
          }

          let trucksDictionary = trucks;

          if (!trucksDictionary) {
            const dict = await fetchDocDictionary(
              query(collection(db, "trucks-p"))
            );
            setTrucks(dict);
            trucksDictionary = dict;
            //console.log("trucks-p", trucksDictionary);
          }

          const inventories = [];

          if (values.locationId) {
            const response = await InventoryDataService.getForLocation(
              values.locationId,
              dateBegin.startOf("day").toDate(),
              dateEnd.startOf("day").toDate()
            );

            inventories.push(...response.data);
          } else {
            const response = await InventoryDataService.getForDates(
              dateBegin.startOf("day").toDate(),
              dateEnd.startOf("day").toDate()
            );

            inventories.push(...response.data);
          }

          console.log("inventories", inventories);

          const ta = [];
          const tr = [];

          inventories.forEach((i) => {
            //console.log(i);
            const location = locations.docs.find((l) => l.id === i.locationId);
            const user = users.docs.find((u) => u.id === i.lastUpdatedBy);

            if (i.tankId) {
              ta.push({
                id: i.id,
                location: location?.data()?.name,
                tankNumber: i.tankNumber,
                productName: productsDictionary[i.productId]?.name,
                productMacro: productsDictionary[i.productId]?.macro,
                gallons: i.gallons,
                percentOfCapacity: i.percentOfCapacity,
                reportingDate: dayjs(i.reportingDate),
                userName: user?.data()?.email,
                driverNumber: user?.data()?.driverNumber,
                createdDate: dayjs(i.createdAt),
              });
            } else {
              tr.push({
                id: i.id,
                location: location?.data()?.name,
                truckNumber: trucksDictionary[i.truckId]?.truckNumber,
                compartment: i.compartment,
                productName: productsDictionary[i.productId]?.name,
                productMacro: productsDictionary[i.productId]?.macro,
                gallons: i.gallons,
                reportingDate: dayjs(i.reportingDate),
                userName: user?.data()?.email,
                driverNumber: user?.data()?.driverNumber,
                createdDate: dayjs(i.createdAt),
              });
            }
          });

          setTankInventories(
            ta.sort((t1, t2) =>
              t1.tankNumber > t2.tankNumber
                ? 1
                : t1.tankNumber < t2.tankNumber
                ? -1
                : t1.reportingDate > t2.reportingDate
                ? 1
                : t1.reportingDate < t2.reportingDate
                ? -1
                : 0
            )
          );
          setTruckInventories(
            tr.sort((t1, t2) =>
              t1.truckNumber > t2.truckNumber
                ? 1
                : t1.truckNumber < t2.truckNumber
                ? -1
                : t1.reportingDate > t2.reportingDate
                ? 1
                : t1.reportingDate < t2.reportingDate
                ? -1
                : 0
            )
          );

          //const done = await new Promise((res) => setTimeout(res, 2500));
          setTableLoading(false);
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            {locationsError && (
              <Grid item xs={12}>
                <strong>Error: {JSON.stringify(locationsError)}</strong>
              </Grid>
            )}
            {locationsLoading && (
              <Grid item xs={12}>
                <Skeleton />
              </Grid>
            )}
            {locations && (
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    component={CustomizedSelect}
                    label="Select Location"
                    name={`locationId`}
                  >
                    <MenuItem value="" name="Location Not Selected" selected>
                      Location Not Selected
                    </MenuItem>
                    {locations.docs.map((doc) => (
                      <MenuItem
                        key={doc.id}
                        value={doc.id}
                        name={doc.data().name}
                      >
                        {doc.data().name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateBegin"
                    label="Begin"
                    value={dateBegin}
                    onChange={(newValue) => setDateBegin(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={3}>
                  <DatePicker
                    fullWidth
                    name="dateEnd"
                    label="End"
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                  ></DatePicker>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="0.9rem" />
                      ) : (
                        <RefreshIcon />
                      )
                    }
                  >
                    {isSubmitting ? "Refreshing" : "Refresh"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            aria-label="tabs"
            centered
            variant="fullWidth"
          >
            <Tab label="Tanks" {...a11yProps(0)} />
            <Tab label="Trucks" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={valueTab} index={0}>
          {tankInventories && (
            <DataGrid
              loading={tableLoading}
              autoHeight
              density="compact"
              slots={{ toolbar: GridToolbar }}
              columns={tankColumns}
              rows={tankInventories}
              getRowId={(tank) => tank.id}
              //onRowClick={handleTankRowClick}
              initialState={{
                columns: { columnVisibilityModel: { location: true, userName: true, createdDate: true, } },
              }}
            />
          )}
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          {truckInventories && (
            <DataGrid
              loading={tableLoading}
              autoHeight
              density="compact"
              slots={{ toolbar: GridToolbar }}
              columns={truckColumns}
              rows={truckInventories}
              getRowId={(truck) => truck.id}
              //onRowClick={handleTruckRowClick}
              initialState={{
                columns: { columnVisibilityModel: { location: true, userName: true, createdDate: true, } },
              }}
            />
          )}
        </TabPanel>
      </Box>
    </Stack>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
